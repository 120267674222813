import React from "react"

export default function Infobox(props) {
  return (
    <div className="flex">
      <div className="flex-shrink-0">
        <div className="flex items-center justify-center h-12 w-12 rounded-full bg-tempory_blue_400_alpha_15 dark:bg-gray-700 text-tempory_blue_400 dark:text-tempory_blue_200">
          {props.icon}
        </div>
      </div>
      <div className="ml-8">
        <dt className="text-lg leading-6 font-semibold text-gray-600 dark:text-gray-200">
          {props.title}
        </dt>
        <dd className="mt-2 text-base font-medium text-gray-400 dark:text-gray-400">
          {props.message}
        </dd>
      </div>
    </div>
  )
}
