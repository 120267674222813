import React from "react"
import "../styles/layout.css"
import hero from "../../img/hero.png"

export default function Header(props) {
  return (
    <div className="z-0 pt-16 md:pt-0">
      <div className="h-2/4 w-full grid grid-cols-1 md:grid-cols-3">
        <div className="">
          <div style={{ maxHeight: `min-content` }} className="center-v">
            <h1 className="max-w-min sm:pb-8 text-4xl lg:text-6xl font-bold bg-gradient-to-r from-tempory_blue_400 to-tempory_logo_pink">
              {props.title}
            </h1>
            <p
              className="sm:pb-0 mt-4 max-w-2xl text-xl font-medium text-gray-600 dark:text-gray-200"
              style={{ maxWidth: 350 }}
            >
              {props.subtitle}
            </p>
          </div>
        </div>
        <div className="col-span-2">
          <div className="mx-auto test-div-inner">
            <img src={hero} className="center-v test-image" />
          </div>
        </div>
      </div>
    </div>
  )
}
