import React from "react"
import Header from "../components/header"
import LayoutOuter from "../components/layout_outer"
import LayoutInner from "../components/layout_inner"
import TestimonialsLayout from "../components/testimonials_layout"
import Testimonial from "../components/testimonial"
import Infobox from "../components/infobox"
import InfoboxLayout from "../components/infobox_layout"
import Gallery from "../components/gallery"

export default function Home() {
  return (
    <LayoutOuter>
      <LayoutInner>
        <Header
          title="Tempory"
          subtitle="Experience the planning app for sales representatives who care about top customer relationships."
        />

        <InfoboxLayout>
          <Infobox
            icon={<span className="material-icons">schedule</span>}
            title="Automatic schedule creation"
            message="Tempory determines when its time to get in touch again by using your visit history and selected priority for a given customer."
          />
          <Infobox
            icon={<span className="material-icons">event</span>}
            title="Keeps track of your visits"
            message="Tempory remembers where you have been already so you can concentrate on the important stuff. Adding or editing visits is super easy and helps to avoid inconsistencies."
          />
          <Infobox
            icon={<span className="material-icons">phone_in_talk</span>}
            title="Instant access to contact options"
            message="Tempory harnesses the power of tools you already use and are familiar with and acts as the central point of interaction to your customers. Call, write E-Mails or even send a WhatsApp message right from the app."
          />
          <Infobox
            icon={<span className="material-icons">directions</span>}
            title="Google Maps integration"
            message="Tempory gets the latest contact information right from your address book and helps getting you to your customers quickly and efficiently."
          />
        </InfoboxLayout>

        <Gallery />
      </LayoutInner>
    </LayoutOuter>
  )
}
