import React from "react"

export default function Layout({ children }) {
  return (
    <div className="mt-10 dark:opacity-20 lg:rounded-xl">
      <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-32 md:gap-y-16">
        {children}
      </dl>
    </div>
  )
}
