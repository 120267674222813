import React from "react"
import "../styles/layout.css"
import GalleryHeader from "./gallery_header"
import maps from "../../img/maps.png"
import customers from "../../img/customers.png"
import dark from "../../img/dark.png"

export default function Gallery() {
  return (
    <div className="pt-24">
      <GalleryHeader
        title="Easy login"
        subtitle="Use your existing accounts so you don't have to remember yet another password."
      />

      <img className="mx-auto h-full w-full" alt="" src={dark} />

      <GalleryHeader
        title="Your Schedule"
        subtitle="Tempory keeps your important customers always right at your fingertips."
      />

      <img className="mx-auto h-full w-3/4" alt="" src={customers} />

      <GalleryHeader
        title="Start your day right"
        subtitle="Tempory prepares a route for your scheduled visits so you don't need to create one manually."
      />

      <img className="mx-auto h-full w-3/4" alt="" src={maps} />
    </div>
  )
}
